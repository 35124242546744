//import ExperienceTable from "./ExperienceTable";
import ImageColumn from "../layout/ImageColumn";
import PageHeading from "./PageHeading";
// import WhatIDo from "./WhatIDo";
import {motion} from "framer-motion";



function About() {
    return(
        <motion.div exit={ {opacity: 0} } animate={ {opacity: 1} } initial={ {opacity: 0} } className="page__container">
            <ImageColumn />
            <div className="page--content">
                <div className="about">
                    <PageHeading />
                    <p className="paragraph">Hey there! I'm Ruby (affectionately known online as <a className="paragraph" href="https://twitter.com/ruby_rafaelina" target="_blank">@ruby_rafaelina</a>).</p>
                    <p className="paragraph">I’m a tech career consultant and a project manager, but I also write copy like a pro - because I am one! I’ve written SEO-friendly copy for web agencies, tech blogs, email campaigns and, of course, my standard (but very viral) Twitter threads. The topics I cover are many and varied, but are generally geared towards lifestyle, culture, tech, and career transition topics.</p>
                    <p className="paragraph">But enough about me. Let's talk about you. I assume you’re here to fill a very specific need. So let me tell you what I can do for you. If what you need is quickly regurgitated copy that the user understands but doesn’t engage with, it’d probably be best to just use ChatGPT (no shade to ChatGPT but it’s no copywriter - sorry, not sorry!) </p>
                    <p className="paragraph">If you’re after engaging copy that drives conversions and helps build brand identity, then look no further! Head on over to the contact page, and let’s work!</p>
                    {/* <WhatIDo /> */}
                </div>
            </div>
        </motion.div>
    );
}

export default About;