import {useState, useEffect} from 'react';

const portfolio = [
    {
        image: "https://ik.imagekit.io/webzemi/Ruby-Bonilla-Portfolio/Portfolio/Main/webzemi_gKOpR37FwJ.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677085409143",
        name: "WebZemi",
        description: "As the project lead for WebZemi's home page build, I got to flex my project management skills by overseeing the entire build from start to finish. I managed a diverse team of talented web developers, designers, and wordsmiths (aka copywriters) to make sure everything ran smoothly, and that we hit our deadlines on time, on budget, and most importantly, within scope. As with any large project, it was a wild ride for sure, but we made it to the finish line and launched a killer website that speaks to the team's expertise and contributed to a 12% increase in new clientele.",
        category: ["all", "project manager", "writer", "consultant"],
        key: 1
    },
    {
        image: "https://ik.imagekit.io/webzemi/Ruby-Bonilla-Portfolio/Portfolio/Main/blogs_GPPtqS0LX5.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677085409547",
        name: "Blogs, Books, and Beyond",
        description: "Blogs, Books, and Beyond (or BBB, as we took to calling it), was a 3-day online event aimed at creating and launching a platform for writers, publishers, editors, illustrators and copywriters to learn and grow together and create meaningful business connections. The main goal was to connect with industry experts in the field of writing and publishing, but the deeper aim was to increase brand awareness and to make sure that everyone in the publishing world knew what BBB was about. As the project lead, I worked closely with our team to ensure that the event was geared towards catering to the unique needs of all these different groups, while also fostering a sense of community and learning. Despite the challenges of coordinating across different four time zones, three different languages, and nine different countries, we were able to successfully execute an event that brought together creative minds from all over the world.",
        category: ["all", "consultant"],
        key: 2
    },
    {
        image: "https://ik.imagekit.io/webzemi/Ruby-Bonilla-Portfolio/Portfolio/Main/acuity_-7x-Eo1t4.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677085408764",
        name: "Acuity Brands",
        description: "Acuity Brands is a leading industry retailer in the U.S. They were in need of a revamp of their Investor Relations website, and it was a pleasure to work closely with their team to make it happen. We wanted to create a site that not only provided relevant and accurate information to investors, but also highlighted the company's values and successes. I was grateful to have worked with an amazingly talented and enthusiastic team for this build. The level of dedication shown by all our team members was contagious, and made everyone else very enthusiastic about bringing the vision to life. Everyone from the external communications team to the technical teams brought a unique perspective and a lot of heart to the work they produced. For this site, we went with a mobile-first approach to create a design and UX flow that was easy to navigate, visually appealing, and. most importantly, informative for the stakeholders. Overall, the project was a great success, and Acuity Brands continues to receive positive feedback from the updates and changes months later.",
        category: ["all", "project manager"],
        key: 3},
    // },
    // {
    //     image: "https://ik.imagekit.io/webzemi/Ruby-Bonilla-Portfolio/Portfolio/Main/workhuman_NeAbTgXmo-.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677085409710",
    //     name: "Spotlight (Workhuman)",
    //     description: "Workhuman is a leading industry expert in the field of HR, and a foremost authority in the realm of employee recognition. Their Social Recognition software, which has been used by industry giants such as LinkedIn, Cisco, Merck, and BP, has been responsible for tremendous positive impacts to employee satisfaction levels. Through their software and services, Workhuman has continued to push the limits of what employee recognition can do, and has contributed to the evolution of the human-centered workplace experience. I've had the pleasure of working with Workhuman over several different projects, including the creation and launch of a landing page for their quarterly Spotlight event. The event's landing page featured an interactive schedule, speaker profiles, and other resources to help attendees make the most of their time. It was a rewarding project, and I am proud to have played a part in the success of this, and other projects with Workhuman.",
    //     category: ["all", "project manager"],
    //     key: 4
    // },
    // {
    //     image: "https://ik.imagekit.io/webzemi/Ruby-Bonilla-Portfolio/Portfolio/Main/kingdom__-AMXW1dj.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677085411434",
    //     name: "Kingdom Women",
    //     description: " Leading the project to create a website for Surreal Eyewear, a leading eyewear retailer, was an exciting and challenging opportunity. Working closely with the team at Surreal Eyewear, I gained a deep understanding of their customer base and unique approach to eyewear sales. My goal was to design a website that captured their brand identity and showcased their extensive collection of eyewear. Collaborating with a team of designers and developers, we created a visually appealing and user-friendly site that made it easy for customers to browse and purchase eyewear. By employing a clean design and seamless functionality, we aimed to enhance the overall user experience, which ultimately contributed to increased sales and customer satisfaction.",
    //     category: ["all", "writer", "project manager"],
    //     key: 5
    // },
    {
        image: "https://ik.imagekit.io/webzemi/Ruby-Bonilla-Portfolio/Portfolio/Main/surreal_Q1Enuk79Ht.png?ik-sdk-version=javascript-1.4.3&updatedAt=1677085411057",
        name: "Surreal Eyewear",
        description: "Leading the project to create a website for Surreal Eyewear was an exciting and challenging opportunity. Surreal is an eyewear boutique, famous for its beautiful designer glasses and eyewear accessories. Surreal serves the funkiest and most eclectic of New York City's clientele, and they needed a website to match their outgoing and unapologetic reputation. With the help of our amazing designer and talented web team, we delivered a fun, modern, and sleek website, which prominently features their prized collections from some of the world's most well-known designers. By employing a clean design and seamless functionality, we aimed to enhance the overall user experience, which ultimately contributed to increased sales and customer satisfaction.",
        category: ["all", "project manager"],
        key: 5
    }
];


const FilterTable = () => {    
    const [filter, setFilter] = useState('all');
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        setProjects(portfolio);
    }, []);
    
    useEffect(() => {
        setProjects([]);
        const filtered = portfolio.map( p => ({ ...p, filtered: p.category.includes(filter) }) );
        setProjects(filtered);
    }, [filter]);

    return(
        <div className="table__container">
            <div className="filter-label__container" id="labelContainer">
                <button active={filter === 'all'} onClick={ ()=> setFilter('all')} className="filter-label filter-label__active">All</button>
                <button active={filter === 'writer'} onClick={ ()=> setFilter('writer') } className="filter-label">Writing</button>
                <button active={filter === 'project manager'} onClick={ ()=> setFilter('project manager')} className="filter-label">Project Management</button>
                <button active={filter === 'consultant'} onClick={ ()=> setFilter('consultant')} className="filter-label">Consulting</button>
            </div>
            <div className="table">
                {projects?.map(item => item.filtered === true ? (
                    <div className="table-item" key={item.key}>
                        <img key={item.Image} src={item.image} alt="portfolio item" className="table-item--image"/>
                        <h3 key={item.name} className="heading heading-sml">{item.name}</h3>
                        <p key={item.description} className="paragraph">{item.description}</p>
                    </div>
                ) : '')}
            </div>
            <>
                {(() => {
                    let labels = document.getElementsByClassName("filter-label");
                    
                    for(let i = 0; i < labels.length; i++){
                        labels[i].addEventListener("click", function(){
                            let current = document.getElementsByClassName("filter-label__active");
                            current[0].className = current[0].className.replace("filter-label__active", "");
                            this.className += " filter-label__active";
                        })
                    }
                })()}
            </>
        </div>
    )
}

export default FilterTable;